<template>
  <div class='flex row-around'>
    <div class="box_item pl20 pr20 flex row-column row-center col-center" style="color: #C6FE37;">
      <div style="font-family: DS-Digital-BoldItalic, DS-Digital;" class="fs48 ">{{obj.greenNum}}</div>
      <div class="line"></div>
      <div class="color-f fs16 fw-600 text-center"> (台) <br>设备总数</div>
    </div>
    <div class="box_item pl20 pr20 flex row-column row-center col-center" style="color: #C6FE37;">
      <div style="font-family: DS-Digital-BoldItalic, DS-Digital;" class="fs48 ">0</div>
      <div class="line"></div>
      <div class="color-f fs16 fw-600 text-center"> (台) <br>故障设备数</div>
    </div>
    <div class="box_item pl20 pr20 flex row-column row-center col-center" style="color: #C6FE37;">
      <div style="font-family: DS-Digital-BoldItalic, DS-Digital;" class="fs48 ">{{hours}}</div>
      <div class="line"></div>
      <div class="color-f fs16 fw-600 text-center"> (h) <br>设备消毒时长</div>
    </div>
  </div>
</template>

<script>
import { getoverview } from "@/api/sterilizer"
import { getWorkingHours } from "@/api/Robot"
import clearTime from '@/mixin/clearTime.js'
export default {
  name: 'robotView',
  mixins: [clearTime],
  data () {
    return {
      obj: {},
      hours: null
    };
  },
  created () {
    this.getoverview()
    this.getWorkingHours()
    this.timer = setInterval(() => {
      this.getoverview()
      this.getWorkingHours()
    }, 180000)
  },

  mounted () { },

  methods: {
    async getoverview () {
      const { data } = await getoverview()
      this.obj = data[3]
    },
    async getWorkingHours () {
      const { data } = await getWorkingHours()
      console.log(333, data);
      this.hours = (data.area / 3600).toFixed(1)
    }
  }
}

</script>
<style lang='scss' scoped>
.box_item {
  width: 170px;
  height: 148px;
  border-left: 2px solid #a4ca44;
  border-right: 2px solid #a4ca44;
  border-radius: 10px;
  background: rgba(88, 108, 18, 1);
  .line {
    opacity: 0.2;
    border: 0.5px solid #ffffff;
  }
}
</style>