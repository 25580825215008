<template>
  <div>
    <top :shouback="true" />
    <div class='container flex color-f'>
      <!-- 左边的盒子 -->
      <div class="box_l flex1 h-max pt37 color-f">
        <div class="mb36 pl22 pr128">
          <yimiao />
        </div>
        <!-- 今日检测记录 -->
        <div class="pl30">
          <!-- 标题 -->
          <div class="header_title fs16">
            <div class="yuan"></div>
            <div class="header_ch">今日检测记录</div>
            <div class="header_en">{{"Daily detection record".toUpperCase()}}</div>
          </div>
          <div class="pr138">
            <todayTestList />
          </div>
        </div>
        <!-- //今日检测记录 -->
        <!-- 健康码检测情况 -->
        <div class="mt20 pl30">
          <!-- 标题 -->
          <div class="header_title fs16">
            <div class="yuan"></div>
            <div class="header_ch">健康码检测情况</div>
            <div class="header_en">{{"Health code detection".toUpperCase()}}</div>
          </div>
          <div class="pr141">
            <healthyCode class="mt40" />
          </div>

        </div>
        <!-- //健康码检测情况 -->

        <!-- 体温数据监测 -->
        <div class="mt57 pl30">
          <!-- 标题 -->
          <div class="header_title fs16">
            <div class="yuan"></div>
            <div class="header_ch">体温数据监测</div>
            <div class="header_en">{{"Temperature data monitoring".toUpperCase()}}</div>
          </div>
          <div class="pr126">
            <tiwenData />
          </div>
        </div>
        <!-- //体温数据监测 -->
      </div>
      <!-- 左边的盒子 -->

      <!-- 中间的盒子 -->
      <div class="box_center w488 h-max">
        <img src="@/assets/img/4.0/topHeader.png" class="w330 h12 mt23" style="margin:0 auto">
        <!-- 今日检测人数 -->
        <div class="mt30 w-max countNum relative" style="z-index:2">
          <CountFlop style="margin:0 auto" :val='testNum' title='今日检测人数' suffix='次' />
        </div>
        <!-- //今日检测人数 -->

        <!-- 中间旋转区域 -->
        <div class="center_rotate">
          <centerRotate />
        </div>
        <!-- //中间旋转区域 -->

        <!-- 中间底部的盒子 -->
        <div class="center_b relative" style="z-index:2">
          <shebeiUseList />
        </div>
        <!-- //中间底部的盒子 -->
      </div>
      <!-- //中间的盒子 -->

      <!-- 右边的盒子 -->
      <div class="box_r flex1 h-max pt29 mr26">
        <!-- 防疫机器人概览 -->
        <div class="pl104 pr22">
          <div class="header_title fs16">
            <div class="yuan"></div>
            <div class="header_ch">防疫机器人概览</div>
            <div class="header_en">{{"Overview of epidemic prevention robots".toUpperCase()}}</div>
          </div>
          <!-- 组件 -->
          <div class="mt20">
            <robotView />
          </div>
        </div>
        <!-- //防疫机器人概览 -->

        <!-- 设备运行轨迹 -->
        <div class="pl104 pr22 mt40">
          <div class="header_title fs16 mb6">
            <div class="yuan"></div>
            <div class="header_ch">设备位置监测</div>
            <div class="header_en">{{"Equipment position monitoring".toUpperCase()}}</div>
          </div>
          <!-- 组件 -->
          <div>
            <!-- <shebeiRun /> -->
            <AddListener :list='list' ref="addDom" />
          </div>
          <!-- //组件 -->
        </div>
        <!-- //设备运行轨迹 -->

        <!-- 近一周消毒时长 -->
        <div class="pl103 pr22 mt40">
          <div class="header_title fs16 mb6">
            <div class="yuan"></div>
            <div class="header_ch">近一周消毒时长</div>
            <div class="header_en">{{"Duration of disinfection in recent week".toUpperCase()}}</div>
          </div>
          <!-- 组件 -->
          <div>
            <XiaoduArea />
          </div>
          <!-- //组件 -->
        </div>
        <!-- //近一周消毒时长 -->

        <!-- 机器人使用情况 -->
        <div class="pl103 pr22 mt18">
          <div class="header_title fs16 mb6">
            <div class="yuan"></div>
            <div class="header_ch">机器人使用情况</div>
            <div class="header_en">{{"Robot usage".toUpperCase()}}</div>
          </div>
          <!-- 组件 -->
          <div class="mt7">
            <RobotState :list='list' />
          </div>
          <!-- //组件 -->
        </div>
        <!-- //机器人使用情况 -->
      </div>
      <!-- //右边的盒子 -->
    </div>
  </div>

</template>

<script>
import top from '@/components/top.vue'
import yimiao from './components/yimiao.vue'
import healthyCode from '@/components/healthyCode'
import tiwenData from '@/components/tiwenData'
import CountFlop from '@/components/CountFlop'
import centerRotate from '@/views/robot/components/centerRotate'
// import robotUstList from '@/views/twoRobot/components/robotUstList.vue'
import robotView from '@/views/oneRobot/components/robotView.vue'
import todayTestList from '@/views/oneRobot/components/todayTestList.vue'
import XiaoduArea from '@/components/xiaoduArea/index'
// import shebeiUseList from '@/views/robot/components/shebeiUseList'
import { todayDefends } from '@/api/wang.js'
import clearTime from '@/mixin/clearTime.js'
import { getNetLocation } from "@/api/Robot"
import ShebeiUseList from '@/views/robot/components/shebeiUseList/index'
import AddListener from '@/views/AllPanle/components/addListener'
import RobotState from '@/components/robotState/index'
export default {
  mixins: [clearTime],
  components: {
    top, yimiao, healthyCode,
    tiwenData,
    RobotState, CountFlop, centerRotate, todayTestList, robotView, XiaoduArea, ShebeiUseList, AddListener
  },
  data () {
    return {
      testNum: '',
      query: {
        pageNum: 1,
        pageSize: 20
      },
      list: []
    };
  },

  created () {
    this.todayDefends()
    this.getNetLocation()
    this.timer = setInterval(() => {
      this.todayDefends()
      this.getNetLocation()
    }, 180000)
  },

  mounted () { },

  methods: {
    async todayDefends () {
      const { data } = await todayDefends(this.query)
      this.toOrderNum(data.count)
    },
    //转换千分位
    toOrderNum (num) {
      num = num.toString()
      if (num.length < 7) {
        num = '0' + num
        this.toOrderNum(num)
      } else {
        //加逗号
        num = num.slice(1, 4) + ',' + num.slice(4, 7)
        // this.count = num.split('') 
        this.testNum = num
      }
    },
    //机器人使用情况  设备位置监测
    async getNetLocation () {
      const { data } = await getNetLocation()
      data.forEach(item => {
        if (item.parameter7 == 0) {
          item.state = '未运行'
        } else if (item.parameter7 == 1) {
          item.state = '运行中'
        } else {
          item.state = '未知'
        }
      })
      this.list = data
      this.$nextTick(() => {
        if (this.$refs.addDom) {
          this.$refs.addDom.setName()
        }
      })
    }
  }
}

</script>
<style lang='scss' scoped>
.container {
  width: 100%;
  height: 1004px;
  background: #000;
  perspective: 1800px;
  .box_l {
    transform: rotateY(10deg);
    background: url("~@/assets/img/4.0/box_l.png") no-repeat;
    // background-size: 100% 100%;
    background-size: contain;
  }
  .box_r {
    transform: rotateY(-10deg);
    background: url("~@/assets/img/4.0/box_r.png") no-repeat;
    // background-size: 100% 100%;
    background-size: contain;
  }
  .center_rotate {
    margin-top: -70px;
  }
  .center_b {
    margin-left: -70px;
    margin-top: -90px;
  }
}
</style>